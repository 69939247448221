import { palette } from '@synoptic/ui-kit/palette.ts';
import clsx from 'clsx';
import {
  semiCircleProgressBarContainer,
  semiCircleProgressBar,
  semiCircleContainer,
  smallSemiCircleProgressBar,
} from './semi-circle-progress-bar.css';
import { semiCircleProgressBarValue } from '@/components/reputation-bet/reputation-bet.css.ts';

export const SemiCircleProgressBar = ({
  value,
  className,
  size,
}: {
  value: number;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}) => {
  const fixedValue = Number(value.toFixed(1));
  return (
    <div className={clsx(semiCircleContainer, className)}>
      <div
        className={semiCircleProgressBarContainer}
        data-testid={'post-reputation-bet'}
      >
        <svg
          className={clsx(
            semiCircleProgressBar,
            size === 'small' && smallSemiCircleProgressBar,
          )}
          viewBox="0 0 60 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.00396 30.4538C3.94197 26.9024 4.60829 23.376 5.96181 20.0922C7.31532 16.8083 9.32734 13.8366 11.8737 11.3603"
            stroke={fixedValue ? palette.purple[500] : palette.grey[400]}
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M19.4248 6.24782C22.6696 4.80314 26.176 4.03862 29.7277 4.00143C33.2794 3.96423 36.801 4.65515 40.0754 6.03155"
            stroke={fixedValue >= 1.1 ? palette.purple[500] : palette.grey[400]}
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M48.0611 11.2972C50.6161 13.7645 52.6385 16.7292 54.0035 20.0083C55.3684 23.2875 56.0471 26.8115 55.9975 30.363"
            stroke={fixedValue >= 2.1 ? palette.purple[500] : palette.grey[400]}
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
        <div className={semiCircleProgressBarValue}>x{fixedValue}</div>
      </div>
    </div>
  );
};
